<script lang="ts" setup>
// to load component based on screen size
const isMediumScreen = useMediaQuery('(min-width: 768px)')
</script>

<template>
  <div id="layout" class="flex flex-col relative bg-white text-black dark:text-white dark:bg-dark">
    <nav class="p-5 w-full border-b border-common lg:px-4">
      <div class="flex w-full items-center">
        <a class="mr-4 flex-inline items-center" href="/" aria-label="Hubble Exchange">
          <NavbarLogoOnly class="max-h-40px" />
          <ClientOnly>
            <LazyNavbarLogoText
              v-if="isMediumScreen"
              class="ml-1 hidden md:block max-h-40px dark:text-white text-black"
            />
          </ClientOnly>
        </a>
        <div class="ml-auto">
          <ColorMode class="p-2.76 md:px-7 md:py-3.25" />
        </div>
      </div>
    </nav>
    <main class="flex flex-col flex-grow px-4 py-2.5">
      <slot />
    </main>
    <Footer />
  </div>
</template>
